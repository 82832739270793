.App {
  text-align: center;
}
.image-container {
  position: relative;
  display: inline-block;
}
.feature-container {
  display: flex;
  gap: 2rem;
}
.feature {
  height: 17vmin;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-record {
  height: 60vmin;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
  .App-record {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-size: cover; /* Ajusta el tamaño de la imagen de fondo */
  background-position: center; /* Centra la imagen de fondo */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Main-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.secondary-row{
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.text-copy{
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.email{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

.email a{
  color: white;
  font-size: larger;
}
.email p{
  font-size: medium;
}
.email:hover {
  color: #000;
}
.email a:hover {
  color: #F7372A;
}

.social-media-icons {
  margin-top: 0px;
}

.social-media-icons a {
  color: white;
  margin: 0 10px;
  transition: color 0.3s;
}

.Instagram:hover {
  color: #E1306C;
}
.Spotify:hover {
  color: #1ED760;
}

@media (max-width: 1024px) {
  .feature-container{
    display: none;
  }
}
@media (max-width: 820px) {
  .App-record, .App-logo {
    height: 90vmin;
  }
  .secondary-row{
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .frase{
    color: #000;
  }
}